import React from 'react';
// import { Link } from 'react-router-dom'
// import Menu from "./Menu";
import { Event, wait, __ } from '../../../../Helper';
import Engine from '../../../Games/Crash/Engine';
import socket from '../../../../Socket';
import C from '../../../../Constant';
import storage from '../../../../Storage';
import Submit from './Submit';

class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            chatLoaded: false,
            show: true,
            engine: Engine,
            clientCountry: storage.getKey('country')
                ? storage.getKey('country')
                : 'GLOBAL',
            chat: [],
            navHeight: 80,
            showSideBar: true,
        };
        this.chatBody = React.createRef();
    }

    componentDidMount() {
        Event.on('toggle_sidebar', () => {
            this.setState({ show: !this.state.show });
        });

        wait(2500).then(() => {
            var c = this.state.clientCountry;
            socket.emit(C.CHATS, c);
        });

        socket.on(C.CHATS, (data) => this.getChats(data));
        socket.on(C.ADD_CHAT, (data) => this.getNewChats(data));

        window.addEventListener('resize', () => {
            let pnavbar = document.getElementById('pnavbar');
            this.setState({ navHeight: pnavbar.clientHeight });
        });
        let pnavbar = document.getElementById('pnavbar');
        this.setState({ navHeight: pnavbar.clientHeight });
    }

    toggleSidebar() {
        this.setState({ showSideBar: !this.state.showSideBar });
    }

    getChats(data) {
        // console.log(data, "chat");
        this.setState({
            ...this.state,
            chat: data,
            chatLoaded: true,
            loading: false,
        });
        setTimeout(() => {
            // console.log(this.chatBody.scrollHeight);
            this.chatBody.current.scrollTop =
                this.chatBody?.current?.scrollHeight;
        }, 200);
    }

    getNewChats(data) {
        // console.log('****GETTING NEW CHATS****');
        this.setState({ ...this.state, chat: [...this.state.chat, data] });
        setTimeout(() => {
            console.log(this.chatBody.scrollHeight);
            this.chatBody.current.scrollTop =
                this.chatBody.current.scrollHeight;
        }, 200);
    }

    renderChats() {
        return this.state.chat.map(({ name, message, id }, index) => {
            return (
                <div key={id || index} className="left-sidenav-item">
                    <div className="left-sidenav-item-text">
                        {name?.slice(0, 6)}:
                    </div>
                    <div className="left-sidenav-item-text">{message}</div>
                </div>
            );
        });
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <button
                    onClick={() => this.toggleSidebar()}
                    className={
                        'btn rounded notif-btn tbtn btn-ocol px-1 py-1 left-sidenav-header_mobile'
                    }
                >
                    <i
                        className={`mdi ${
                            this.state.showSideBar
                                ? 'mdi-comment-arrow-left-outline'
                                : 'mdi-comment-arrow-right-outline'
                        } align-middle font-25 left-sidenav-header-icon`}
                    />
                </button>
                {/* <div
                    style={{ display: 'none' }}
                    className={`left-sidenav left-sidenav-custom`}
                ></div> */}
                {true && (
                    <div
                        style={{
                            display: this.state.showSideBar ? 'block' : 'none',
                        }}
                        className={`left-sidenav left-sidenav-custom left-sidenav-show`}
                    >
                        <div className="left-sidenav-header">
                            <i class="fas fa-comments left-sidenav-header-icon"></i>
                        </div>
                        <div
                            ref={this.chatBody}
                            className="left-sidenav-body"
                            style={{ paddingBottom: '3rem' }}
                        >
                            {this.state.loading && (
                                <div style={{ padding: '1rem' }}>
                                    <div className="text-center pb-3">
                                        <div
                                            className="spinner-border text-danger"
                                            role="status"
                                        />
                                    </div>
                                </div>
                            )}

                            {this.renderChats()}
                        </div>
                        <div className="left-sidenav-footer">
                            <Submit loggedIn={this.props.loggedIn} t={t} />
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default SideBar;
