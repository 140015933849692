import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { injected } from '../wallet/connectors';
import { useWeb3React } from '@web3-react/core';
import { ParabloicAbi } from './abis';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { nanoid } from 'nanoid';

export const MetaMaskContext = React.createContext(null);

export const MetaMaskProvider = ({ children }) => {
    const { activate, account, library, connector, active, deactivate } =
        useWeb3React();
    const [signature, setSignature] = useState(null);
    const [status, setStatus] = useState('');

    const [isActive, setIsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // const listener = (block) => {
    //     console.log("new action emited")
    //     console.log(block)
    //     getAllActions()
    // }

    // Init Loading
    // useEffect(() => {
    //     connect().then((val) => {
    //         setIsLoading(false);
    //     });
    // }, []);

    const web312 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545');

    const contractAddr = '0x9AEC82BC850e1aA2607E8A5F753cE73d8fd0D0d4';
    const ParabolicContract = new web312.eth.Contract(
        ParabloicAbi,
        contractAddr
    );

    let contractSubObject = ParabolicContract.events.Transfer({
        // fromBlock: result.blockNumber,
    });

    contractSubObject.on('connected', function (subId) {
        // console.log("New contract subscription active for "+ candidateAddress + " with sub id " + subId);
        // contractSubscriptionDetail.subscriptionObject = contractSubObject
    });
    contractSubObject.on('data', function (event) {
        console.log('hello world!');
        if (
            event.returnValues.from == account &&
            event.returnValues.to != contractAddr
        )
            console.log(event.returnValues.value);
        // saveEventTransaction(event, candidateAddress)
    });

    // Init Loading
    useEffect(() => {
        (async () => {
            if (account) {
                let provider = new ethers.providers.Web3Provider(
                    window.ethereum
                );
                let signer = provider.getSigner();
                let signString = `contents: \nPlease sign your wallet. (${nanoid(
                    6
                )})`;
                let signature = await signer.signMessage(signString);
                try {
                    const signerAddress = ethers.utils.verifyMessage(
                        signString,
                        signature
                    );
                    if (account === signerAddress) {
                        setSignature(signature);
                    }
                } catch (err) {
                    await disconnect();
                }
            }
        })();
    }, [account]);

    const handleIsActive = useCallback(() => {
        setIsActive(active);
    }, [active]);

    useEffect(() => {
        handleIsActive();
    }, [handleIsActive]);

    // Connect to MetaMask wallet
    const connect = async () => {
        if (!isActive || !account) {
            setStatus('CONNECTING');
            await disconnect();
        }
        try {
            let res = await activate(injected);
            if (res) {
                setStatus('CONNECTED');
            }
        } catch (error) {
            setStatus('CONNECTION_ERROR');
        }
    };

    // Disconnect from Metamask wallet
    const disconnect = async () => {
        // storage.setKey('address', '');
        // storage.setKey('token', '');
        try {
            await deactivate();
            setSignature(null);
        } catch (error) {}
    };

    // Deposit
    const deposit = async (amount) => {
        // signer

        console.log('accounts2 => ' + account);
        // paymentAddress is where funds will be send to
        const paymentAddress = '0x0d71F8e68F9486125faEC2f5B7Bd466e53d5C66C';

        const contractAddr = '0x9AEC82BC850e1aA2607E8A5F753cE73d8fd0D0d4';

        const ParabolicContract = await new library.eth.Contract(
            ParabloicAbi,
            contractAddr
        );

        await ParabolicContract.methods
            .transfer(paymentAddress, amount * 10 ** 9)
            .send({ from: account })
            .then(function (result) {
                console.log(result);
                console.log(result.status);
                console.log('amount => ' + amount);

                /* let contractSubObject = ParabolicContract.events.Transfer({
                fromBlock: result.blockNumber,
            })

            contractSubObject.on('connected', function (subId) {
                // console.log("New contract subscription active for "+ candidateAddress + " with sub id " + subId);
                // contractSubscriptionDetail.subscriptionObject = contractSubObject
            })
            contractSubObject.on('data', function (event) {
                if (event.returnValues.from == account && event.returnValues.to != contractAddr)
                    console.log(event.returnValues.value);
                // saveEventTransaction(event, candidateAddress)
            }) */
            })
            .catch(console.error);
    };

    const web31 = new Web3(window.ethereum);
    // const web31 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545');

    // var subscription = web31.eth.subscribe('newBlockHeaders', function (error, result) {
    //     if (!error) {
    //         console.log(result);

    //         return;
    //     }

    //     console.error(error);
    // })
    //     .on("connected", function (subscriptionId) {
    //         console.log(subscriptionId);
    //     })
    //     .on("data", function (blockHeader) {
    //         console.log(blockHeader);
    //     })
    //     .on("error", console.error);

    // // unsubscribes the subscription
    // subscription.unsubscribe(function (error, success) {
    //     if (success) {
    //         console.log('Successfully unsubscribed!');
    //     }
    // });

    const values = useMemo(
        () => ({
            isActive,
            account,
            isLoading,
            deposit,
            connect,
            disconnect,
            signature,
            status,
        }),
        [isActive, isLoading, signature, status]
    );

    return (
        <MetaMaskContext.Provider value={values}>
            {children}
        </MetaMaskContext.Provider>
    );
};

export default function useMetaMask() {
    const context = React.useContext(MetaMaskContext);

    if (context === undefined) {
        throw new Error(
            'useMetaMask hook must be used with a MetaMaskProvider component'
        );
    }

    return context;
}
