import React from 'react';
import { Dropdown } from 'react-bootstrap';
import Chat from './../PChat';
import { Event } from '../../../../Helper';
import Logout from '../../../Pages/Auth/Logout';
import UpdateUsername from '../../Auth/UpdateUsername';
import Deposit from '../../Auth/Deposit';
import Wallet from '../../../Components/User/Wallet';
import Setting from '../../../Components/User/Setting';

var show = true;

class Header extends React.Component {
    state = {
        sampleHits: [
            { value: 20, increase: true },
            { value: 12, increase: false },
            { value: 30, increase: true },
            { value: 5, increase: false },
            { value: 12, increase: true },
            { value: 3, increase: false },
            { value: 23, increase: true },
            { value: 1, increase: true },
            { value: 3, increase: false },
            { value: 23, increase: true },
            { value: 1, increase: true },
            { value: 3, increase: false },
        ],
    };
    collapse = () => {
        show = !show;
        Event.emit('toggle_sidebar', show);
    };

    componentDidMount() {
        // console.log(this.props.account, '***send to backend***');
    }

    renderHits = () => {
        // console.log(this.state.sampleHits);
        return this.state.sampleHits.map((sh, index) => {
            return (
                <span
                    style={{
                        display: 'flex',
                        padding: '5px',
                        margin: '0px 10px',
                        fontSize: `${0.05 * (10 - index) + 0.8}rem`,
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            fontSize: '1rem',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#555',
                        }}
                    >{`#${index + 1}`}</span>

                    <span>
                        {sh.increase ? (
                            <span
                                style={{
                                    display: 'inline-block',
                                    fontSize: `${0.08 * (10 - index) + 0.8}rem`,
                                    color: 'red',
                                    transform: 'rotate(-90deg)',
                                    // marginTop: '-7px',
                                    marginLeft: '5px',
                                }}
                            >
                                &laquo;
                            </span>
                        ) : (
                            <span
                                style={{
                                    display: 'inline-block',
                                    fontSize: `${0.05 * (10 - index) + 0.8}rem`,
                                    color: 'green',
                                    transform: 'rotate(-90deg)',
                                    // marginTop: '-8px',
                                    marginLeft: '5px',
                                }}
                            >
                                &raquo;
                            </span>
                        )}
                    </span>
                    <span
                        style={{
                            fontSize: '0.9rem',
                            display: 'flex',
                            color: '#888',
                            margin: '0rem .2rem',
                            alignItems: 'center',
                        }}
                    >
                        {sh.value}
                    </span>
                    <img
                        src="/assets/images/pancakeswap.png"
                        style={{
                            width: '1rem',
                            height: '1rem',
                            borderRadius: '1rem',
                        }}
                        alt="img"
                    />
                </span>
            );
        });
    };
    render() {
        const { t } = this.props;
        return (
            <div className="ptopbar" id={'ptopbar'}>
                <nav id={'pnavbar'} className="navbar-custom">
                    <div className="top-navbar-custom">
                        <div className="ptop-navbar-left">
                            <div className="">
                                <i class="fas fa-fire ptop-navbar-left-icon"></i>
                                <span className="ptop-navbar-left-title">
                                    Trending
                                </span>
                            </div>
                            <div className="ptop-navbar-trending-container">
                                <div className="ptop-navbar-trending-item">
                                    <span class="ptop-navbar-trending-item-num">
                                        1
                                    </span>
                                    <div className="ptop-navbar-trending-item-image-container">
                                        <img
                                            class="ptop-navbar-trending-item-image"
                                            src="https://robohash.org/lightening?size=20x20"
                                            alt="avt"
                                        />
                                    </div>
                                    <span className="ptop-navbar-left-item-title">
                                        Lightening
                                    </span>
                                    <span class="ptop-navbar-trending-item-text">
                                        Next Launch
                                    </span>
                                </div>
                                <div className="ptop-navbar-trending-item">
                                    <span class="ptop-navbar-trending-item-num">
                                        2
                                    </span>
                                    <div className="ptop-navbar-trending-item-image-container">
                                        <img
                                            class="ptop-navbar-trending-item-image"
                                            src="https://robohash.org/site.png?size=20x20"
                                            alt="avt"
                                        />
                                    </div>
                                    <span className="ptop-navbar-left-item-title">
                                        Lorem
                                    </span>
                                    <span class="ptop-navbar-trending-item-text"></span>
                                </div>
                                <div className="ptop-navbar-trending-item">
                                    <span class="ptop-navbar-trending-item-num">
                                        3
                                    </span>
                                    <div className="ptop-navbar-trending-item-image-container">
                                        <img
                                            class="ptop-navbar-trending-item-image"
                                            src="https://robohash.org/dollar.png?size=20x20"
                                            alt="avt"
                                        />
                                    </div>
                                    <span className="ptop-navbar-left-item-title">
                                        Dollar
                                    </span>
                                    <span class="ptop-navbar-trending-item-text"></span>
                                </div>
                            </div>
                        </div>
                        <div className="top-navbar-middle">
                            <img
                                src="./assets/images/A2-white-transprant1.png"
                                alt="parabolic"
                                className="top-navbar-logo"
                            />
                            {/* <h1 className="top-navbar-logo">
                                {' '}
                                <div style={{ display: 'inline-block' }}>
                                    Parab
                                </div>
                                <div
                                    style={{ display: 'inline-block' }}
                                    className="page_header_logo"
                                >
                                    <i className="fas fa-space-shuttle"></i>
                                </div>
                                <div style={{ display: 'inline-block' }}>
                                    lic
                                </div>
                            </h1> */}
                            <nav
                                className="navbar-custom"
                                style={{ marginTop: '1rem' }}
                            >
                                <ul className="list-unstyled topbar-nav float-right mb-0 ml-auto">
                                    <li className={'guest-dropdown'}>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                split
                                                variant=""
                                                id="dropdown-split-user"
                                                // onClick={async () => {
                                                //     this.props.connect();
                                                // }}
                                            >
                                                {/* <img
                                                    src="/assets/images/avatar.svg"
                                                    alt="profile-user"
                                                    className="rounded-circle w-40"
                                                /> */}
                                                <span className="ml-1 nav-user-name account_button">
                                                    Manage Account:
                                                    {this.props.address
                                                        ?.toString()
                                                        .slice(0, 12)}
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className={'droper'}>
                                                <Dropdown.Item
                                                    as={'button'}
                                                    className={
                                                        'btn animated fadeIn'
                                                    }
                                                >
                                                    <Logout
                                                        disconnect={
                                                            this.props
                                                                .disconnect
                                                        }
                                                    />
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    as={'button'}
                                                    className={
                                                        'btn animated fadeIn'
                                                    }
                                                >
                                                    <Setting
                                                        username={
                                                            this.props.address
                                                        }
                                                        t={t}
                                                    />
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    as={'button'}
                                                    className={
                                                        'btn animated fadeIn'
                                                    }
                                                >
                                                    <Wallet t={t} />
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="ptop-navbar-left">
                            <div className="">
                                <i class="fab fa-superpowers ptop-navbar-right-icon"></i>
                                <span className="ptop-navbar-left-title">
                                    Biggest Gainers
                                </span>
                            </div>
                            <div className="ptop-navbar-trending-container">
                                <div className="ptop-navbar-trending-item">
                                    <span class="ptop-navbar-trending-item-num">
                                        1
                                    </span>
                                    <div className="ptop-navbar-trending-item-image-container">
                                        <img
                                            class="ptop-navbar-trending-item-image"
                                            src="https://robohash.org/gainer1?size=20x20"
                                            alt="avt"
                                        />
                                    </div>
                                    <span className="ptop-navbar-left-item-title">
                                        Gainer 1
                                    </span>
                                    <span class="ptop-navbar-trending-item-text">
                                        some txt
                                    </span>
                                </div>
                                <div className="ptop-navbar-trending-item">
                                    <span class="ptop-navbar-trending-item-num">
                                        2
                                    </span>
                                    <div className="ptop-navbar-trending-item-image-container">
                                        <img
                                            class="ptop-navbar-trending-item-image"
                                            src="https://robohash.org/gainer2.png?size=20x20"
                                            alt="avt"
                                        />
                                    </div>
                                    <span className="ptop-navbar-left-item-title">
                                        GAiner 2
                                    </span>
                                    <span class="ptop-navbar-trending-item-text">
                                        ipsome
                                    </span>
                                </div>
                                <div className="ptop-navbar-trending-item">
                                    <span class="ptop-navbar-trending-item-num">
                                        3
                                    </span>
                                    <div className="ptop-navbar-trending-item-image-container">
                                        <img
                                            class="ptop-navbar-trending-item-image"
                                            src="https://robohash.org/gainer3.png?size=20x20"
                                            alt="avt"
                                        />
                                    </div>
                                    <span className="ptop-navbar-left-item-title">
                                        Gainer 3
                                    </span>
                                    <span class="ptop-navbar-trending-item-text">
                                        site emit
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div
                        style={{
                            // marginTop: '-1rem',
                            height: '2rem',
                            width: '110%',
                            backgroundColor: '#1B1D23',
                            border: '1px solid #555',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '-1rem',
                        }}
                    >
                        <div
                            style={{
                                height: '2rem',
                                width: '12rem',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                color: '#fff',
                                // backgroundColor: '#555',
                                borderRight: '1px solid #555',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // padding: '.5rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Hot Wins
                        </div>
                        <div className="marquee">
                            <p
                                style={{
                                    display: 'flex',
                                    color: 'white',
                                    alignItems: 'center',
                                    overflowX: 'hidden',
                                }}
                            >
                                {this.renderHits()}
                            </p>
                        </div>
                    </div> */}
                    <ul className="list-unstyled topbar-nav float-right mb-0 ml-auto">
                        <li className={'chat-sidebar'}>
                            <Chat
                                toggleChat={this.props.toggleChat}
                                show={this.props.show}
                                t={t}
                                location={this.props.location}
                            />
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Header;
