import React, { useEffect, useState } from 'react';
import { decode, encode, Event } from '../../../../Helper';
import socket from '../../../../Socket';
import C from '../../../../Constant';
import storage from '../../../../Storage';
import Cookies from 'js-cookie';
import useMetaMask, { MetaMaskContext } from '../../../../hooks/metamask';
import { useContext } from 'react';
import Credit from '../../../Pages/Parts/Credit';
import RealTime from '../../../Pages/Parts/RealTime';
import Wallet from '../../../Components/User/Wallet';
// import PropTypes from 'prop-types'

function GameHeader(props) {
    const { t } = props;
    const [auth, setAuth] = useState(false);
    const { connect, disconnect, isActive, account } = useMetaMask();
    const metaMaskContext = useContext(MetaMaskContext);
    const [address, setAddress] = useState(account);

    useEffect(() => {
        socket.on(C.WALLET_CONNECT_ONLINE, (status) => login(decode(status)));

        const sessionCookie = Cookies.get('auth');

        if (storage.getKey('token') !== null && sessionCookie) {
            socket.emit(
                C.WALLET_CONNECT_ONLINE,
                encode({
                    jwt_token: storage.getKey('jwt_token'), //fake
                    user_token: storage.getKey('user_token'), //fake
                    security_key: storage.getKey('security_key'), //fake
                    secret_user: storage.getKey('secret_user'), //fake
                    secret_realtime: storage.getKey('secret_realtime'), //fake
                    client_system: storage.getKey('client_system'), //fake
                    token_key: storage.getKey('token_key'), //fake
                    secret_token: storage.getKey('secret_token'), //fake
                    token: storage.getKey('token'), // REAL
                    address: storage.getKey('address'), // REAL
                })
            );
        }
    }, []);

    const login = (data) => {
        setAuth(data.status);
        setAddress(data.address);
    };

    return (
        <div className="game_header">
            <RealTime t={t} />
            <div className="game_header_left">
                <div className="game_header_clogo">
                    {/* <i className="fas fa-space-shuttle game_header_logo"></i> */}
                    <img
                        src="./assets/images/rocket.jpg"
                        alt="parabolic"
                        width={50}
                        height={50}
                    />
                </div>
                <div className="game_header_ctitle">
                    <div className="game_header_title">
                        Parabolic (PARA/BNB){' '}
                        <i className="fas fa-star game_header_star"></i>
                    </div>
                    <p className="game_header_value">$0.000000366285</p>
                </div>
            </div>
            <div className="game_header_right" style={{ alignItems: 'center' }}>
                <a
                    className="game_header_website"
                    href="https://parabolicCrash/"
                    target="blank"
                >
                    <i className="fas fa-globe game_header_website_logo"></i>
                    <div className="game_header_title game_header_website">
                        Website
                    </div>
                </a>
                {/* <div className="game_header_but">
                    <i className="fas fa-chart-bar"></i>
                </div> */}

                <div style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}>
                    <Wallet text="Cashier Wallet" t={t} />
                </div>
                {storage.getKey('token') !== null ? (
                    <>
                        <div
                            className="game_header_but"
                            style={{
                                backgroundColor: '#1B1D23',
                                padding: '0px',
                                marginTop: '-.5rem',
                            }}
                        >
                            <Credit t={t} />
                        </div>
                        {/* <div
                            className="game_header_but"
                            style={{
                                backgroundColor: '#1B1D23',
                                padding: '0px',
                            }}
                        >
                            <User t={t} />
                        </div> */}
                        {/* <div
                            className="game_header_but"
                            style={{
                                backgroundColor: '#1B1D23',
                                padding: '0px',
                            }}
                        >
                            <Notifications t={t} />
                        </div> */}
                    </>
                ) : (
                    <div
                        className="game_header_but"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        100
                    </div>
                )}
            </div>
        </div>
    );
}

export default GameHeader;
