import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
import { MetaMaskProvider } from './hooks/metamask';

// Styles
import './Static/css/bootstrap.min.css';
import './Static/css/animate.css';
import './Static/css/icons.css';
import 'react-notifications-component/dist/theme.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './Static/css/metisMenu.min.css';
import './Static/css/extra.css';
import './Static/css/app.css';

import './i18n';

function getLibrary(provider, connector) {
    return new Web3(provider);
}

ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <MetaMaskProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </MetaMaskProvider>
    </Web3ReactProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
