import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Modal } from 'react-bootstrap';
import PerfectScrollbar from 'perfect-scrollbar';
import coins from '../../../coins';
import socket from '../../../../Socket';
import storage from '../../../../Storage';
import { setCoin, gameCoin, setCredit } from '../../../../actions/gameCoin';
import {
    __,
    wait,
    decode,
    encode,
    forceSatoshiFormat,
    Event,
    COIN_TOKEN,
} from '../../../../Helper';
import C from '../../../../Constant';

class Credit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            token: storage.getKey('token'),
            coin: COIN_TOKEN,
            selectedCoin: COIN_TOKEN,
            credits: {},
            coins: [],
            different: [],
            credit: false,
        };
        this.selectCoin = this.selectCoin.bind(this);
    }

    componentDidMount() {
        //Get Redux Coin
        this.props.gameCoin();

        if (this.state.token !== null)
            socket.emit(
                C.CREDIT,
                encode({ token: this.state.token, coin: this.state.coin })
            );

        socket.on(C.CREDIT, (data) => this.getCredit(decode(data)));
        socket.on(C.UPDATE_CREDIT, (data) => this.updateCredit(decode(data)));
    }

    updateAllCoins() {
        this.setState({ coins: [] });

        coins.forEach((item, key) => {
            var style =
                __.lowerCase(item.preffix) ===
                __.lowerCase(this.state.selectedCoin)
                    ? 'actc'
                    : '';
            var value = forceSatoshiFormat(
                this.state.credits[__.lowerCase(item.preffix)]
            );
            var coin = (
                <Dropdown.Item
                    onClick={(e) => this.selectCoin(item.preffix)}
                    key={key}
                    className={'num-style ' + style}
                >
                    <div className="float-left">
                        <img
                            src={'/assets/images/' + item.image}
                            alt="coin"
                            className={'mini-coin-7'}
                        />
                        {item.preffix}
                    </div>
                    <div className="float-right">{value}</div>
                </Dropdown.Item>
            );
            this.setState((state) => ({ coins: [coin, ...state.coins] }));
        });
    }

    getCredit(data) {
        let { credit } = data;

        this.setState({ credits: credit });

        let currentCoin = this.state.coin;

        let currentCredit = forceSatoshiFormat(this.state.credits[currentCoin]);
        this.setState({ credit: currentCredit });
        storage.setKey('credit', currentCredit);

        //Set for Redux
        this.props.setCredit(currentCredit);
        this.updateAllCoins();
    }

    updateCredit(data) {
        let credit = data.value;
        let coin = data.coin;

        let different;
        let arr;

        if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
            different = __.toNumber(credit) - __.toNumber(this.state.credit);
            arr = {
                amount: different,
                color: 'text-success',
            };
        } else {
            different = __.toNumber(this.state.credit) - __.toNumber(credit);
            arr = {
                amount: different,
                color: 'text-danger',
            };
        }

        let check = forceSatoshiFormat(different);

        if (check.toString() !== '0.00000000') {
            this.setState((state) => ({
                different: [arr, ...state.different],
            }));
        }

        this.setState({ credit: forceSatoshiFormat(credit) });
        storage.setKey('credit', credit);
        this.updateParentCoin(__.lowerCase(coin), credit);

        //Set for Redux
        this.props.setCredit(forceSatoshiFormat(credit));
    }

    updateParentCoin(coin, amount) {
        this.setState({ coins: [] });

        coins.forEach((item, key) => {
            if (__.lowerCase(item.preffix) === coin) {
                const style =
                    __.lowerCase(item.preffix) ===
                    __.lowerCase(this.state.selectedCoin)
                        ? 'actc'
                        : '';
                let update = (
                    <Dropdown.Item
                        onClick={(e) => this.selectCoin(__.upperCase(coin))}
                        key={key}
                        className={'num-style ' + style}
                    >
                        <div className="float-left">
                            <img
                                src={'/assets/images/' + item.image}
                                alt="coin"
                                className={'mini-coin-7'}
                            />
                            {item.preffix}
                        </div>
                        <div className="float-right">
                            {forceSatoshiFormat(amount)}
                        </div>
                    </Dropdown.Item>
                );
                this.setState((state) => ({ coins: [update, ...state.coins] }));
            } else {
                const style =
                    __.lowerCase(item.preffix) ===
                    __.lowerCase(this.state.selectedCoin)
                        ? 'actc'
                        : '';
                let value = forceSatoshiFormat(
                    this.state.credits[__.lowerCase(item.preffix)]
                );
                let update = (
                    <Dropdown.Item
                        onClick={(e) => this.selectCoin(item.preffix)}
                        key={key}
                        className={'num-style ' + style}
                    >
                        <div className="float-left">
                            <img
                                src={'/assets/images/' + item.image}
                                alt="coin"
                                className={'mini-coin-7'}
                            />
                            {item.preffix}
                        </div>
                        <div className="float-right">{value}</div>
                    </Dropdown.Item>
                );
                this.setState((state) => ({ coins: [update, ...state.coins] }));
            }
        });
    }

    selectCoin(name) {
        //Fix For Double selection
        if (storage.getKey('coin') === name) return;
        storage.setKey('coin', name);

        let credit = this.state.credits[__.lowerCase(name)];
        this.setState({ coin: name, credit: credit, selectedCoin: name });

        //Set Coin For Redux
        this.props.setCoin(name);
        this.props.setCredit(credit);

        wait(200).then(() => {
            this.updateAllCoins();
            Event.emit('coin_changed');
        });
    }

    addDiff(data, i) {
        let id = 'id_' + Math.floor(Math.random() * 1000 + 1);

        wait(2000).then(() => {
            try {
                document.getElementById(id).classList.remove('frd');
                document.getElementById(id).classList.add('fadeOutDown');
            } catch (e) {}
            this.state.different.splice(i, 1);
        });

        return (
            <li
                style={{ marginTop: '1.5rem' }}
                key={i}
                id={id}
                className={
                    'list-inline w-100 text-right animated frd ' + data.color
                }
            >
                {' '}
                {data.color === 'text-danger' ? '-' : '+'}
                {forceSatoshiFormat(data.amount, this.state.coin)}
            </li>
        );
    }

    open = () => {
        this.setState({ show: !this.state.show });
    };

    render() {
        let { credit, different, coin, coins, show } = this.state;
        credit = forceSatoshiFormat(credit, coin);
        let diff = different.map((data, i) => this.addDiff(data, i));

        const style = show ? 'show' : 'd-nones';
        const { t } = this.props;
        return (
            <>
                <li>
                    <div
                        className="crypto-balance text-center"
                        style={{ position: 'relative' }}
                    >
                        <span id="cup2" className={'mt-2 mr-1'}>
                            {diff}
                        </span>
                        <div className="btc-balance">
                            <div
                                className={'clist mt-2 text-right float-right'}
                            >
                                <div
                                    split
                                    variant="block"
                                    className={'p-0 mt-0'}
                                    id="dropdown-split-coins"
                                >
                                    <h5 className="m-0 text-center d-inline-block float-left">
                                        <span className={'text-white'}>
                                            <img
                                                src={
                                                    '/assets/images/' +
                                                    __.lowerCase(coin) +
                                                    '.png'
                                                }
                                                className={'mini-coin-2 mr-2'}
                                                alt=""
                                            />
                                        </span>
                                    </h5>
                                    <span className="text-white d-block font-14 d-inline-block float-right">
                                        {credit}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </>
        );
    }
}

Credit.propTypes = {
    setCoin: PropTypes.func.isRequired,
    coin: PropTypes.string,
};

const mapStateToProps = (state) => ({
    coin: state.items.coin,
});

export default connect(mapStateToProps, { setCoin, gameCoin, setCredit })(
    Credit
);
