import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import GameHeader from './Header';
import Engine from '../Engine';
// import { Modal } from 'react-bootstrap';
import useMetaMask, { MetaMaskContext } from '../../../../hooks/metamask';
import { useContext } from 'react';
import storage from '../../../../Storage';
import { useSelector, useDispatch } from 'react-redux';
import { Game, __, sendNotfication, Event, decode } from '../../../../Helper';
import { setWallet } from '../../../../actions/gameWallet';
import C from '../../../../Constant';
import socket from '../../../../Socket';

import { setMeToGame } from '../../../../actions/crashGame';

let gGameStatus = '';

let i = 0;

let gData = [];

const GameStatus = {
    STARTED: 'STARTED',
    WAITING: 'WAITING',
    BUSTED: 'BUSTED',
    END: 'END',
    FINISHED: 'FINISHED',
};

function Chart({ setMax, ...props }) {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [graphData, setGraphData] = useState([]);

    const [multiplexer, setMultiplexer] = useState(1);

    const canvas = useRef();

    const engine = Engine;

    function handleResize() {
        // Set window width/height to state
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    let i = 0;
    /**
     * Handle adding new candle
     */
    let addCandle = (c = null) => {
        i++;
        let newData = {};
        let close = 0.001 * c;
        if (c < 0) close = 0;
        let high = Math.max(close + (Math.random() / 10) * close, 0);
        let low = Math.max(close - (Math.random() / 10) * close, 0);
        newData = {
            open: gData[gData?.length - 1]?.close || 0.001,
            close: close,
            high: high,
            low: low,
            date: moment(gData[gData?.length - 1]?.date || new Date()).add(
                5,
                'minutes'
            ),
            volume: close * 1000 * Math.random(),
        };
        if (i === 2) gData = [newData];
        else gData.push(newData);
        if (i !== 1) {
            setGraphData([...graphData, newData]);
        }
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        engine.trigger.on('busted_crash', (data) => busted(data));
        engine.trigger.on('new_point', (data) => newPoint(data));
    }, []);

    const newPoint = (data) => {
        i++;
        if (data.point > 0 || multiplexer > 0) {
            addCandle(parseFloat(data.point));
            setMultiplexer(data.point > 0 ? data.point : 0);
            engine.multiplexer = data.point > 0 ? data.point : 0;
        }
    };

    const busted = (data) => {
        setTimeout(() => {
            gData = [];
            setGraphData([]);
        }, 5000);
    };

    useEffect(() => {
        if (graphData) {
            let minLocal = gData[0]?.close || 0.001;
            let maxLocal = gData[0]?.close || 0.001;
            gData.forEach((d) => {
                minLocal = minLocal > d.close ? d.close : minLocal;
                maxLocal = maxLocal < d.close ? d.close : maxLocal;
            });
            setMax(maxLocal / 0.001);
            initialiseChart(gData);
        }
        return () => {
            d3.selectAll('svg').remove();
            d3.selectAll('g > *').remove();
        };
    }, [windowSize, graphData]);

    const initialiseChart = (data) => {
        const margin = { top: 20, right: 35, bottom: 25, left: 20 };
        const width = canvas?.current?.clientWidth - margin.left - margin.right;
        const height =
            canvas?.current?.clientHeight - margin.top - margin.bottom;
        // add SVG to the page
        let svg = d3
            .select('#chart')
            .append('svg')
            .attr('width', width + margin['left'] + margin['right'])
            .attr('height', height + margin['top'] + margin['bottom'])
            .call(d3.zoom().scaleExtent([0.1, 60]).on('zoom', zoom))
            .call(responsively)
            .append('g')
            .attr(
                'transform',
                `translate(${margin['left']},  ${margin['top']})`
            );

        // find data range
        const xMin = d3.min(data, (d) => {
            return new Date(d['date']);
        });
        const xMax = d3.max(data, (d) => {
            return new Date(d['date']);
        });
        let xGap = Math.max((xMax - xMin) / (data.length * 0.016), 600000);
        const yMin = Math.min(
            d3.min(data, (d) => {
                return d['close'];
            })
        );
        const yMax = Math.max(
            d3.max(data, (d) => {
                return d['close'];
            })
        );

        // scales for the charts
        const xScale = d3
            .scaleTime()
            .domain([xMin, moment(new Date(xMax).getTime() + xGap)])
            .range([0, width]);
        let crossShareXScale = xScale;
        const yScale = d3
            .scaleLinear()
            .domain([0, yMax + 0.0001])
            .range([height, 0]);
        let crossShareYScale = yScale;

        // create the axes component
        let x = svg
            .append('g')
            .attr('id', 'xAxis')
            .attr('transform', `translate(-18, ${height})`)
            .call(d3.axisBottom(xScale));
        svg.append('g')
            .call(d3.axisRight(yScale))
            .attr('id', 'yAxis')
            .attr('transform', `translate(${width - 18}, 0)`);

        var xAxis = d3.axisBottom(xScale);

        d3.axisRight(yScale);

        /* Volume series bars */
        const volData = data.filter(
            (d) => d['volume'] !== null && d['volume'] !== 0
        );
        const yMinVolume = d3.min(volData, (d) => {
            return Math.min(d['volume']);
        });
        const yMaxVolume = d3.max(volData, (d) => {
            return Math.max(d['volume']);
        });
        const yVolumeScale = d3
            .scaleLinear()
            .domain([yMinVolume, yMaxVolume])
            .range([height, height / 1.3]);

        let vBar = svg
            .selectAll()
            .data(volData)
            .enter()
            .append('rect')
            .attr('x', (d) => {
                return xScale(new Date(d['date']));
            })
            .attr('y', (d) => {
                return yVolumeScale(d['volume']);
            })
            .attr('fill', (d, i) => {
                // if (i === 0) {
                //     return '#F4413A';
                // } else {
                return (volData[i - 1]?.close || 0.001) > d.close
                    ? '#F4413A'
                    : '#30AD9D';
                // }
            })
            .attr('width', 1.2)
            .attr('height', (d) => {
                return height - yVolumeScale(d['volume']);
            });

        // render candlestick v-line
        let candleStickVLine = svg
            .selectAll()
            .data(data)
            .enter()
            .append('rect')
            .attr('x', (d) => {
                return (
                    xScale(new Date(d['date'])) +
                    Math.min((width * 1) / (data.length || 1), 0.018 * width) /
                        2 -
                    1.5
                );
            })
            .attr('y', (d) => {
                let candleTop = d3.max([d.open, d.close]);
                let stickTop = d3.max([d.high, d.low]);
                let candleHeight =
                    Math.abs(yScale(d.open) - yScale(d.close)) + 1.5;
                let stickHeight = Math.abs(yScale(d.high) - yScale(d.low));
                if (stickTop - stickHeight > candleTop) {
                    return candleTop;
                }
                return yScale(d3.max([d.high, d.low]));
            })
            .attr('fill', (d, i) => {
                // if (i === 0) {
                //     return '#F4413A';
                // } else {
                return (data[i - 1]?.close || 0.001) > d.close
                    ? '#F4413A'
                    : '#30AD9D';
                // }
            })
            .attr('width', 1.5)
            .attr('height', (d) => {
                return Math.abs(yScale(d.high) - yScale(d.low));
            });

        // render candlestick box
        let candleStickBox = svg
            .selectAll()
            .data(data)
            .enter()
            .append('rect')
            .attr('x', (d) => {
                return xScale(new Date(d['date'])) - 2;
            })
            .attr('y', (d) => {
                return yScale(d3.max([d.open, d.close]));
            })
            .attr('fill', (d, i) => {
                // if (i === 0) {
                //     return '#F4413A';
                // } else {
                return (data[i - 1]?.close || 0.001) > d.close
                    ? '#F4413A'
                    : '#30AD9D';
                // }
            })
            .attr('width', Math.min(width / (data.length || 1), 0.018 * width))
            .attr('height', (d) => {
                return Math.abs(yScale(d.open) - yScale(d.close)) + 1.5;
            });

        let imageTracker = svg
            .data([data.slice(data.length - 1, data.length)])
            .append('text')
            .attr('font-family', 'FontAwesome')
            .style('font-size', '20px')
            .style('fill', 'white')
            .text(function (d) {
                return '\uf135';
            })
            .attr('x', (d) => {
                d = d[0];
                if (!d) return;
                return xScale(new Date(d['date'])) - 20;
            })
            .attr('y', (d) => {
                d = d[0];
                return yScale(d3.max([d?.open, d?.close])) - 0.4;
            });

        svg.selectAll()
            .data([data.slice(data.length - 1, data.length)])
            .enter()
            .append('rect')
            .attr('x', (d) => {
                return width - 15;
            })
            .attr('y', (d) => {
                d = d[0];
                return yScale(d3.max([d?.open, d?.close])) - 10;
            })
            .attr('fill', '#2CA69A')
            .attr('width', 80)
            .attr('height', 20);

        const line = d3
            .line()
            .x((d, i) => {
                return i === 0 ? width : 0;
            })
            .y((d) => {
                return yScale(d3.max([d?.open, d?.close]));
            });

        svg.append('path')
            .data([
                [
                    ...data.slice(data.length - 1, data.length),
                    ...data.slice(data.length - 1, data.length),
                ],
            ])
            .style('fill', 'none')
            .attr('id', 'priceChart')
            .attr('stroke', 'steelblue')
            .attr('stroke-width', '1')
            .style('stroke-dasharray', '3, 3')
            .attr('d', line);

        svg.data([data.slice(data.length - 1, data.length)])
            .append('text')
            .text((d) => {
                d = d[0];
                if (!d) return;
                let val = d?.open;
                return val;
            })
            .attr('x', (d) => {
                return width - 4;
            })
            .attr('y', (d) => {
                d = d[0];
                return yScale(d3.max([d?.open, d?.close])) + 4;
            })
            .style('fill', 'white')
            .style('font-size', `10px`)
            .style('font-weight', 'bold')
            .style('text-transform', 'capitalize');

        function zoom(e) {
            // re-scale x axis during zoom
            x.transition()
                .duration(50)
                .call(xAxis.scale(d3.event.transform.rescaleX(xScale)));

            var new_x_scale = d3.event.transform.rescaleX(xScale);
            crossShareXScale = new_x_scale;

            candleStickBox
                .attr('x', function (d) {
                    return new_x_scale(new Date(d['date']));
                })
                .attr('width', () => {
                    return Math.min(
                        5 * (new_x_scale(height) / xScale(height)),
                        40
                    );
                });
            candleStickVLine.attr('x', function (d) {
                return (
                    new_x_scale(new Date(d['date'])) +
                    Math.min(5 * (new_x_scale(height) / xScale(height)), 40) / 2
                );
            });
            // .attr('width', 1 * (new_x_scale(width) / xScale(width)));
            vBar.attr('x', function (d) {
                return new_x_scale(new Date(d['date']));
            }).attr('width', () => {
                return Math.min(5 * (new_x_scale(height) / xScale(height)), 40);
            });
            imageTracker.attr('x', (d) => {
                d = d[0];
                if (!d) return;
                return new_x_scale(new Date(d['date'])) - 20;
            });
        }

        const bisectDate = (data, correspondingDate) => {
            let index = 1;
            data.forEach((d, i) => {
                if (d.date === correspondingDate) {
                    index = i;
                    return;
                }
            });
            return index;
        };
        const updateLegends = (currentData) => {
            d3.selectAll('.lineLegend').remove();
            const legendKeys = ['open', 'close', 'low', 'high', 'date'];
            const lineLegend = svg
                .selectAll('.lineLegend')
                .data(legendKeys)
                .enter()
                .append('g')
                .attr('class', 'lineLegend')
                .attr('transform', (d, i) => {
                    return `translate(${Math.floor(
                        (i * 100 - 22) * (width / 1080)
                    )}, ${-35})`;
                });
            lineLegend
                .append('text')
                .text((d) => {
                    if (d === 'date') {
                        return `${d}: ${
                            currentData[d]
                                ? new Date(currentData[d])
                                      .toISOString()
                                      .replace('T', ' ')
                                      .split('.')[0]
                                : ''
                        }`;
                    } else if (
                        d === 'high' ||
                        d === 'low' ||
                        d === 'open' ||
                        d === 'close'
                    ) {
                        return `${d}: ${currentData[d]?.toFixed(5)}`;
                    } else {
                        return `${d}: ${currentData[d]}`;
                    }
                })
                .style('fill', 'white')
                .style('font-size', `10px`)
                .style('font-weight', 'bold')
                .style('text-transform', 'capitalize')
                .attr('transform', 'translate(15,9)');
        };

        const toClosest15Min = (value) => {
            var coeff = 1000 * 60 * 15;
            var date = new Date(value); //or use any other date
            if (date === 'Invalid Date') return;
            var rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
            if (rounded === 'Invalid Date') return;
            try {
                return rounded?.toISOString();
            } catch (err) {}
        };

        function generateCrosshair() {
            const correspondingDate = toClosest15Min(
                crossShareXScale.invert(d3.mouse(this)[0] - 6)
            );
            const i = bisectDate(data, correspondingDate, 1);
            if (i === 1) return;
            const d0 = data[i - 1] || {};
            const d1 = data[i] || {};
            const currentPoint =
                correspondingDate - d0['date'] > d1['date'] - correspondingDate
                    ? d1
                    : d0;
            focus.attr(
                'transform',
                `translate(${crossShareXScale(
                    new Date(currentPoint['date'])
                )},${height - crossShareYScale(currentPoint['close'])})`
            );
            focus
                .select('line.x')
                .attr('x1', -25)
                .attr('x2', 25)
                .attr('y1', 0)
                .attr('y2', 0);
            focus
                .select('line.y')
                .attr('x1', 0)
                .attr('x2', 0)
                .attr('y1', -25)
                .attr('y2', 25);
            updateLegends(currentPoint);
        }

        // renders x and y crosshair
        const focus = svg
            .append('g')
            .attr('class', 'focus')
            .style('display', 'none');
        focus.append('circle').attr('r', 4.5);
        focus.append('line').classed('x', true);
        focus.append('line').classed('y', true);
        svg.append('rect')
            .attr('class', 'overlay')
            .attr('width', width)
            .attr('height', height)
            .on('mouseover', () => focus.style('display', null))
            .on('mouseout', () => focus.style('display', 'none'))
            .on('mousemove', generateCrosshair);
        d3.select('.overlay').style('fill', 'none');
        d3.select('.overlay').style('pointer-events', 'all');
        d3.selectAll('.focus line').style('fill', 'none');
        d3.selectAll('.focus line').style('stroke', '#67809f');
        d3.selectAll('.focus line').style('stroke-width', '1.5px');
        d3.selectAll('.focus line').style('stroke-dasharray', '3 3');
    };

    function responsively(svg) {
        // get container + svg aspect ratio
        var container = d3.select(svg.node().parentNode),
            width = parseInt(svg.style('width')),
            height = parseInt(svg.style('height')),
            aspect = width / height;

        svg.attr('viewBox', '0 0 ' + width + ' ' + height)
            .attr('perserveAspectRatio', 'xMinYMid')
            .call(resize);

        d3.select(window).on('resize.' + container.attr('id'), resize);

        // get width of container and resize svg to fit it
        function resize() {
            var targetWidth = parseInt(container.style('width'));
            svg.attr('width', targetWidth);
            svg.attr('height', Math.round(targetWidth / aspect));
        }
    }

    return <div id="chart" ref={canvas}></div>;
}

function Button({ children, active, type, onStart = () => {}, ...props }) {
    let style = { alignItems: 'center' };
    return (
        <div className="flex" style={style}>
            <button
                {...props}
                className={`chart_button`}
                style={
                    active
                        ? {
                              display: 'inline-block',
                              marginRight: '0px',
                              borderRadius: '3px',
                              borderTopRightRadius: '0px',
                              borderBottomRightRadius: '0px',
                              backgroundColor: type === 'BET' ? '#DC3D45' : '',
                              border: '2px solid #DC3D45',
                              ...props.style,
                          }
                        : {
                              ...props.style,
                              backgroundColor: type === 'BET' ? '#DC3D45' : '',
                              borderRadius: '3px',
                          }
                }
            >
                {children}
            </button>
            {active ? (
                <button
                    className={`chart_button`}
                    style={{
                        border: '2px solid #DC3D45',
                        backgroundColor: '#DC3D45',
                        color: '#fff',
                        display: 'inline-block',
                        marginLeft: '0px',
                        marginTop: '1px',
                    }}
                >
                    <span
                        onClick={(e) => {
                            e.preventDefault();
                            onStart(e);
                        }}
                        style={{
                            paddingBottom: '0px',
                            display: 'inline-block',
                        }}
                    >
                        Cash Out
                    </span>
                </button>
            ) : (
                ''
            )}
        </div>
    );
}

function Input({ ...props }) {
    return <input type="text" {...props} className="chart_input" />;
}

const MChart = React.memo(Chart);

function GameChart(props) {
    const dispatch = useDispatch();
    const [max, setMax] = useState(1);
    let { t } = props;
    const [betAmount, setBetAmount] = useState(10);
    const [token, setToken] = useState(
        storage.getKey('token') ? storage.getKey('token') : null
    );
    const [address, setAddress] = useState(
        storage.getKey('address') ? storage.getKey('address') : null
    );
    const [payout, setPayout] = useState('2.00');
    const coin = useSelector((state) => state.items.coin);
    const im_in_game = useSelector((state) => {
        return state.items.im_in_game;
    });
    const [percentageValue, setPercentageValue] = useState(100);
    const [holding, setHolding] = useState(false);
    const [added, setAdded] = useState(false);
    const [buttonText, setButtonText] = useState('BET');
    const [inputDisabled, setInputDisabled] = useState(false);
    const [winnerText, setWinnerText] = useState('');
    const [clicked, setClicked] = useState(false);
    const [started, setStarted] = useState(false);
    // const { connect, disconnect, isActive, account } = useMetaMask();
    // const [status, setStatus] = useState(false);
    const metamaskValue = useContext(MetaMaskContext);

    const engine = Engine;
    const [showGameState, setShowGameState] = useState(false);
    const [gameText, setGameText] = useState('You are Busted!!!');
    // const [effect, setEffect] = useState('');
    // const [regEffect, setRegEffect] = useState('');
    const [multiplexer, setMultiplexer] = useState(1);
    const [gameStatus, setGameStatus] = useState('');

    useEffect(() => {
        engine.trigger.on('finish_crash', (data) => finish(data));
        engine.trigger.on('waiting_crash', (data) => waiting(data));
        engine.trigger.on('started_crash', (data) => playStarted(data));
        engine.trigger.on('busted_crash', (data) => busted(data));
        engine.trigger.on('game_status', (data) => status(data));
        engine.trigger.on('new_point', (data) => newPoint(data));
        engine.trigger.on('play_crash', (data) => play(data));
        engine.trigger.on('error_crash', (data) => error(data));
        engine.trigger.on('stop_playing', () => stop());
        engine.trigger.on('auto_bet', (data) => auto(data));
        socket.on(C.WALLET_CONNECT, (data) => setTokenOnLogin(decode(data)));
    }, []);

    const setTokenOnLogin = (data) => {
        if (data.status === true) {
            engine.token = data.token;
            setToken(data.token);
        }
    };

    const auto = (data) => {
        let { amount, payout } = data;

        if (!payout) return alert('payout is empty.');
        if (!amount) return alert('amount is empty.');

        setBetAmount(amount);
        setPayout(payout);

        if (gameStatus === 'started') {
            holdBet();
        } else if (gameStatus === 'waiting') {
            setBet();
        }
    };

    const play = (data, playStarted = false) => {
        // setGameStatus(GameStatus.STARTED);
        // engine.status = GameStatus.STARTED;
        // engine.isHolding = false;
        // setHolding(false);

        if (data.token === engine.token || playStarted) {
            dispatch(setMeToGame(true));
            engine.im_in_game = true;
            // setButtonText('Playing');
        }
    };

    const error = (data) => {
        dispatch(setMeToGame(false));
        engine.im_in_game = false;

        setHolding(true);
        setAdded(true);
        setInputDisabled(true);
        setDefaultButton();

        if (!__.isUndefined(data.code)) {
            if (data.code === 'credit') dispatch(setWallet(true, data.uid));
        }

        setClicked(true);
        sendNotfication(data.message, 'info', 'top-center');
    };

    const stop = (data) => {
        setStarted(false);
        setButtonText('STOPPED');
        setInputDisabled(true);
    };

    const finish = (data) => {
        if (data?.token === engine.token) {
            setGameStatus(GameStatus.FINISHED);
            engine.status = GameStatus.FINISHED;
            if (engine.im_in_game) {
                dispatch(setMeToGame(false));
                engine.im_in_game = false;
                setHolding(false);
                setShowGameState(true);
                setGameText('You Cased Out!!!');
                setWinnerText(
                    `Cash out amount - ${(
                        (engine.amount *
                            engine.percentageValue *
                            engine.multiplexer) /
                        100
                    ).toFixed(2)}`
                );
                setDefaultButton();
            } else {
                dispatch(setMeToGame(false));
                setShowGameState(true);
                setGameText('Finished!!!');
                setDefaultButton();
            }
        }
    };

    const waiting = (data) => {
        setGameStatus(GameStatus.WAITING);
        engine.status = GameStatus.WAITING;
        setGameText('Waiting!!!');
        setShowGameState(true);
        setWinnerText('');
        if (engine.isHolding) {
            placeBet();
        }
    };

    const playStarted = (data) => {
        if (engine.isHolding) {
            play(data, true);
        }
        setTimeout(() => {
            setShowGameState(false);
        }, 500);
        setGameStatus(GameStatus.STARTED);
        engine.status = GameStatus.STARTED;
        setGameText('Started!!!');
    };

    const busted = (data) => {
        if (engine.status === GameStatus.FINISHED) return;
        setShowGameState(true);
        setGameStatus(GameStatus.BUSTED);
        engine.status = GameStatus.BUSTED;
        setGameText('Busted!!!');

        if (!engine.isHolding) {
            dispatch(setMeToGame(false));
            engine.im_in_game = false;
            setHolding(false);
            engine.isHolding = false;
            // clearInterval(this.state.buttonProgress);
            setDefaultButton();
        }

        if (engine.im_in_game) {
            dispatch(setMeToGame(false));
            engine.im_in_game = false;
            // clearInterval(this.state.buttonProgress);
            // setDefaultButton();
        }
    };

    const newPoint = (data) => {
        if (!engine.status) {
            setGameStatus(GameStatus.STARTED);
            engine.status = GameStatus.STARTED;
        }
        i++;
        if (data.point > 0 || multiplexer > 0) {
            setMultiplexer(data.point > 0 ? data.point : 0);
            if (engine.im_in_game && engine.status === GameStatus.STARTED) {
                setButtonText(
                    `Cash Out ${(
                        (engine.amount *
                            engine.percentageValue *
                            engine.multiplexer) /
                        100
                    ).toFixed(2)} ${coin}`
                );
            }
        } else {
            setMultiplexer(data.point > 0 ? data.point : 0);
            setShowGameState(true);
            setGameStatus(GameStatus.BUSTED);
            engine.status = GameStatus.BUSTED;
            setGameText('You are busted!!!');
        }
    };

    const setWaitingButton = () => {
        setInputDisabled(true);
        setAdded(true);
        setButtonText('Please Wait');
    };

    const setBet = () => {
        engine.coin = coin;
        if (token) engine.token = token;
        engine.amount = engine.amount || 10;
        engine.payout = parseInt(payout * 100);
        engine.play();
    };

    const placeBet = () => {
        setHolding(false);
        engine.isHolding = false;
        setWaitingButton();
        setBet();
    };

    const isCorrectBet = () => {
        return true;
    };

    const cashOut = () => {
        engine.finish(Game['current_amount']);
    };

    const setDefaultButton = () => {
        engine.isHolding = false;
        setAdded(false);
        setHolding(false);
        setInputDisabled(false);
        setButtonText('BET');
        dispatch(setMeToGame(false));
    };

    const setOutButton = () => {
        setInputDisabled(false);
        setButtonText('CANCEL (Next Round)');
    };

    const holdBet = () => {
        engine.isHolding = true;
        setHolding(true);
        setOutButton();
    };

    const handleBetPercentCashOut = (e) => {
        e.preventDefault();

        e.preventDefault();
        // Check User

        if (!token && !address) {
            if (storage.getKey('token')) setToken(storage.getKey('token'));
            else if (storage.getKey('address'))
                setAddress(storage.getKey('address'));
            else {
                return Event.emit('showAuthModal', true);
            }
        }

        // Check is Correct Bet
        if (!isCorrectBet()) return false;

        // Check Game Status to Play
        // eslint-disable-next-line
        switch (engine.status) {
            case GameStatus.STARTED:
                if (engine.im_in_game) cashOut();
        }
    };

    const handleBet = (e) => {
        e.preventDefault();
        // Check User

        if (!token && !address) {
            if (storage.getKey('token')) setToken(storage.getKey('token'));
            else if (storage.getKey('address'))
                setAddress(storage.getKey('address'));
            else {
                return Event.emit('showAuthModal', true);
            }
        }

        // Check is Correct Bet
        if (!isCorrectBet()) return false;

        // Check Game Status to Play
        // eslint-disable-next-line
        switch (engine.status) {
            case GameStatus.WAITING:
                placeBet();
                break;

            case GameStatus.BUSTED:
                if (engine.isHolding) {
                    setDefaultButton();
                } else holdBet();
                break;

            case GameStatus.STARTED:
                if (engine.im_in_game) cashOut();
                else if (engine.isHolding) setDefaultButton();
                else holdBet();
                break;
            case GameStatus.FINISHED:
                if (engine.isHolding) setDefaultButton();
                else holdBet();
                break;
        }
    };

    const status = (data) => {
        switch (data.status) {
            case 'waiting':
                waiting();
                break;
            case 'started':
                playStarted();
                break;
            case 'busted':
                busted();
                break;
            default:
        }
    };

    const renderMultipliers = () => {
        let step = (max - 0.0) / 6;
        return (
            <>
                <div className="right_bar__item">
                    <span className="right_bar_times">x</span>
                    {max.toFixed(2)}
                </div>
                <div className="right_bar__item">
                    <span className="right_bar_times">x</span>
                    {(max - step).toFixed(2)}
                </div>
                <div className="right_bar__item">
                    <span className="right_bar_times">x</span>
                    {(max - step * 2).toFixed(2)}
                </div>
                <div className="right_bar__item">
                    <span className="right_bar_times">x</span>
                    {(max - step * 3).toFixed(2)}
                </div>
                <div className="right_bar__item">
                    <span className="right_bar_times">x</span>
                    {(max - step * 4).toFixed(2)}
                </div>
                <div className="right_bar__item">
                    <span className="right_bar_times">x</span>
                    {(max - step * 5).toFixed(2)}
                </div>
                <div className="right_bar__item">
                    <span className="right_bar_times">x</span>
                    {(max - step * 6).toFixed(2)}
                </div>
            </>
        );
    };

    const updatePercentage = (value) => {
        setPercentageValue(value);
        engine.percentageValue = value;
    };

    const onStart = (e) => {
        if (betAmount) {
            handleBet(e);
        }
    };

    const onStartPercent = (e) => {
        if (betAmount) {
            handleBetPercentCashOut(e);
        }
    };

    return (
        <div className="main">
            <GameHeader t={t} />
            <div
                style={{
                    width: '100%',
                    height: '90%',
                    display: 'flex',
                    position: 'relative',
                }}
            >
                <MChart
                    // setMin={setMin}
                    setMax={setMax}
                    show={props.show}
                    toggleChat={props.toggleChat}
                />
                <div className="right_bar">{renderMultipliers()}</div>

                {showGameState && (
                    <div className="game_status">
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: '1.6rem',
                                textWeight: 'bold',
                            }}
                        >
                            <div>{gameText}</div>
                            {gameStatus === GameStatus.WAITING && (
                                <div style={{ padding: '1rem' }}>
                                    <div className="text-center pb-3">
                                        <div
                                            className="spinner-border text-danger"
                                            role="status"
                                        />
                                    </div>
                                </div>
                            )}
                            <div
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.5rem',
                                    color: 'green',
                                }}
                            >
                                {winnerText}
                            </div>
                            <div
                                style={{
                                    fontSize: '1.3rem',
                                    color: 'gray',
                                }}
                            >
                                Current Multiplier
                            </div>
                            <div
                                style={{
                                    fontSize: '1.3rem',
                                    color: 'white',
                                }}
                            >
                                <div style={{ display: 'inline-block' }}>x</div>
                                <div style={{ display: 'inline-block' }}>
                                    {multiplexer}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '2px',
                        backdropFilter: '#555',
                    }}
                >
                    <Button>
                        {' '}
                        <span
                            style={{
                                paddingBottom: '0px',
                                display: 'inline-block',
                            }}
                        >
                            BET AMOUNT:
                        </span>
                    </Button>
                    <Input
                        disabled={inputDisabled}
                        value={betAmount}
                        type="number"
                        onChange={(e) => {
                            setBetAmount(e.target.value);
                            engine.amount = e.target.value;
                        }}
                        placeholder="Amount"
                    />
                </div>

                <Button
                    onClick={onStart}
                    style={{
                        backgroundColor: '#DC3D45',
                    }}
                    type="BET"
                >
                    <span
                        style={{
                            paddingBottom: '0px',
                            display: 'inline-block',
                        }}
                    >
                        {buttonText}
                    </span>
                </Button>
                <Button
                    active={percentageValue === 25}
                    onStart={onStartPercent}
                    onClick={() => {
                        updatePercentage(25);
                    }}
                    // style={{ paddingRight: '1rem' }}
                >
                    <span
                        style={{
                            paddingBottom: '0px',
                            display: 'inline-block',
                        }}
                    >
                        25%
                    </span>
                </Button>
                <Button
                    active={percentageValue === 50}
                    onStart={onStartPercent}
                    onClick={() => {
                        updatePercentage(50);
                    }}
                    // style={{ paddingRight: '1rem' }}
                >
                    <span
                        style={{
                            paddingBottom: '0px',
                            display: 'inline-block',
                        }}
                    >
                        50%
                    </span>
                </Button>
                <Button
                    active={percentageValue === 75}
                    onStart={onStartPercent}
                    onClick={() => {
                        updatePercentage(75);
                    }}
                    // style={{ paddingRight: '1rem' }}
                >
                    <span
                        style={{
                            paddingBottom: '0px',
                            display: 'inline-block',
                        }}
                    >
                        75%
                    </span>
                </Button>
                <Button
                    active={percentageValue === 100}
                    onStart={onStartPercent}
                    onClick={() => {
                        updatePercentage(100);
                    }}
                    // style={{ paddingRight: '1rem' }}
                >
                    <span
                        style={{
                            paddingBottom: '0px',
                            display: 'inline-block',
                        }}
                    >
                        100%
                    </span>
                </Button>
            </div>
        </div>
    );
}

export default GameChart;
