import React from 'react';
import { Modal } from 'react-bootstrap';
import PerfectScrollbar from 'perfect-scrollbar';
import {
    wait,
    isMobile,
    isTablet,
    Event,
    __,
    forceSatoshiFormat,
} from '../../../../Helper';
import Messages from './Messages';
import Submit from './Submit';
import Country from './Country';
import Rain from './Rain';
import Rules from './Rules';
// import socket from "../../../../Socket";
import C from '../../../../Constant';
import Engine from '../../../Games/Crash/Engine';
import storage from '../../../../Storage';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Table } from 'react-bootstrap';
import UserModal from '../../../Components/User/Stat/Modal';
import socket from '../../../../Socket';
import { fixDate, decode, encode, timeConvertor } from '../../../../Helper';
import md5 from 'md5';
import History from './History';

const chatSideBarWidth = 330;

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            engine: Engine,
        };
    }

    componentDidMount() {
        if (isMobile() || isTablet()) {
            this.setState({ show: false });
            this.props.toggleChat(false);
        }
        window.addEventListener('resize', this.handleResize);

        wait(500).then(() => {
            this.handleResize();
        });

        Event.on('toggle_sidebar', () => {
            wait(100).then(() => {
                this.handleResize();
            });
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        const pageContent = document.querySelector('#page-wrapper');
        let clientWidth =
            document.documentElement.clientWidth || document.body.clientWidth;
        const clientHeight =
            document.documentElement.clientHeight || document.body.clientHeight;

        if (pageContent !== null) {
            let space = 250;

            const leftSidebar = document.querySelector('.left-sidenav');
            const games = document.querySelectorAll('.game-image');

            if (hasClass(leftSidebar, 'min') || !this.state.show) {
                space -= 170;

                if (!isMobile()) {
                    games.forEach((g, i) => {
                        if (g !== null) g.style.height = 160 + 'px';
                    });
                }
            } else {
                games.forEach((g, i) => {
                    if (g !== null) g.style.height = 120 + 'px';
                });
            }

            let pWidth =
                this.state.show === true
                    ? clientWidth - chatSideBarWidth
                    : clientWidth;
            pageContent.style.width = pWidth + 'px';

            if (pWidth < 960) {
                Event.emit('hide_games');
            }
        }

        if (!isMobile()) {
            this.setState({ height: clientHeight - 170 });
        } else {
            this.setState({ height: clientHeight - 180 });
        }
    };

    setSide = () => {
        this.setState({ show: !this.state.show });
        this.props.toggleChat(!this.state.show);

        wait(1).then(() => {
            this.handleResize();
        });
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <button
                    onClick={this.setSide}
                    className={
                        'btn rounded notif-btn tbtn btn-ocol px-1 py-1 pright-chat-btn'
                    }
                >
                    <i
                        className={`mdi ${
                            this.state.show
                                ? 'mdi-comment-arrow-right-outline'
                                : 'mdi-comment-arrow-left-outline'
                        } align-middle font-25`}
                    />
                </button>
                {this.state.show && (
                    <Content t={t} height={this.state.height} />
                )}
            </>
        );
    }
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    );
}
class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: chatSideBarWidth + 'px',
            engine: Engine,
            players: [],
            loadingPlayers: true,
            value: 0,
        };
        this.load = this.load.bind(this);
    }

    handleChange(event, newValue) {
        this.setState({ ...this.state, value: newValue });
    }

    gameSync(data) {
        // console.log(data, "History");
    }

    componentDidMount() {
        loadScroll();
        this.load();
        this.handleResize();
        window.addEventListener('resize', this.handleResize);

        const { engine } = this.state;
        engine.getHistory();
        engine.trigger.on(C.HISTORY_CRASH, (data) => this.isWaiting(data));

        engine.trigger.on('finish_crash', (data) =>
            this.syncFinishPlayer(data)
        );
        engine.trigger.on('play_crash', (data) => this.syncPlayPlayer(data));
        engine.trigger.on('busted_crash', (data) => this.isWaiting(data));
        engine.trigger.on('waiting_crash', (data) => this.isWaiting(data));
        engine.trigger.on('game_status', (data) => this.gameSync(data));
        engine.trigger.on('game_players', (data) => this.gameSync(data));
    }

    syncFinishPlayer(data) {
        // console.log(data, "finish crash");
    }

    syncPlayPlayer(data) {
        // console.log(data, "play crash");
    }

    busted(data) {
        // console.log(data, "busted crash");
    }

    isWaiting(data) {
        // console.log(data, "*****data***** adding players");
        if (data?.players?.length === 0) {
            this.setState({ loadingPlayers: false });
            return;
        }
        if (data && data?.players) {
            this.setState({
                players: [...this.state.players, ...data?.players],
            });
        }
    }

    // gameSync(data){
    //     console.log(data, "game status")
    // }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        if (isMobile()) {
            this.setState({ width: '100%' });
        } else {
            this.setState({ width: chatSideBarWidth + 'px' });
        }
    };

    renderPlayers() {
        return this.state.players.map((player, index) => {
            if (player.uid === undefined || player.name === undefined)
                return null;

            let profit = player?.won ? forceSatoshiFormat(player?.won) : '-';

            let bet = player?.amount;

            return (
                <tr key={player.uid || index} className="pright-chat-tr">
                    <td className="pright-chat-td">
                        {player.name ? player.name.slice(0, 5) : ''}
                    </td>
                    <td className="pright-chat-td">{bet}</td>
                    <td className="pright-chat-td">{bet}</td>
                    <td className="pright-chat-td">{profit}</td>
                </tr>
            );
        });
    }

    load() {
        wait(200).then(() => {
            this.state.engine.getPlayers();
        });
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <div
                    className={
                        'main-menu-inner animated slideInRight pright-chat-custom'
                    }
                    style={{ width: this.state.width }}
                >
                    <div className="menu-body slimscroll">
                        <div className="main-icon-menu-pane active">
                            <div className="title-box">
                                <Tabs
                                    value={this.state.value}
                                    onChange={(e, value) =>
                                        this.handleChange(e, value)
                                    }
                                    indicatorColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs"
                                    centered
                                >
                                    <Tab
                                        onClick={() => {
                                            this.load();
                                        }}
                                        icon={
                                            <i
                                                className={'mdi mdi-chart-bar'}
                                            />
                                        }
                                        label="All Bets"
                                        {...a11yProps(0)}
                                    />
                                    {/* <Tab
                                        onClick={() => {
                                            this.load();
                                        }}
                                        icon={
                                            <i
                                                className={
                                                    'mdi mdi-chart-donut'
                                                }
                                            />
                                        }
                                        label="History"
                                        {...a11yProps(2)}
                                    /> */}
                                </Tabs>
                                <TabPanel value={this.state.value} index={0}>
                                    {/* <div className="pright-chat-top">
                                        <div className="pright-chat-top-item">
                                            <span className="pright-chat-top-value">
                                                5
                                            </span>
                                            <span className="pright-chat-top-text">
                                                Player
                                            </span>
                                        </div>
                                        <div className="pright-chat-top-item">
                                            <span className="pright-chat-top-value">
                                                10
                                            </span>
                                            <span className="pright-chat-top-text">
                                                Bet
                                            </span>
                                        </div>
                                        <div className="pright-chat-top-item">
                                            <span className="pright-chat-top-value"></span>
                                            <span className="pright-chat-top-text">
                                                Multiplayer
                                            </span>
                                        </div>
                                        <div className="pright-chat-top-item">
                                            <span className="pright-chat-top-value">
                                                8
                                            </span>
                                            <span className="pright-chat-top-text">
                                                Profit
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        id="messages"
                                        style={{ height: this.props.height }}
                                        className="pright-"
                                    >
                                        <table className="pright-chat-table">
                                            {this.renderPlayers()}
                                            {this.state.loadingPlayers && (
                                                <div
                                                    style={{ padding: "1rem" }}
                                                >
                                                    <div className="text-center pb-3">
                                                        <div
                                                            className="spinner-border text-danger"
                                                            role="status"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {!this.state.loadingPlayers &&
                                                this.state.players.length ===
                                                    0 && (
                                                    <tr
                                                        className="pright-chat-tr"
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <span className="pright-chat-top-text">
                                                            No players found for
                                                            now. Loading...
                                                        </span>
                                                    </tr>
                                                )}
                                        </table>
                                        <div className="left-sidenav-footer"></div>
                                    </div> */}
                                    <AllBets
                                        engine={this.state.engine}
                                        t={this.props.t}
                                        game={this.props.game}
                                    />
                                </TabPanel>
                                <TabPanel value={this.state.value} index={1}>
                                    {/* <div
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <span className="pright-chat-top-text">
                                            Game History
                                        </span>
                                    </div> */}
                                    <History
                                        tab={true}
                                        engine={this.state.engine}
                                        t={this.props.t}
                                        game={this.props.game}
                                    />
                                </TabPanel>
                                <Submit t={t} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function loadScroll() {
    if (document.getElementById('messages') !== null) {
        let ps = new PerfectScrollbar('#messages', {
            wheelSpeed: 1,
            suppressScrollX: true,
            wheelPropagation: true,
            minScrollbarLength: 2,
        });
        ps.update();
    }
}

function hasClass(element, className) {
    return (
        (' ' + element?.className + ' ')?.indexOf(' ' + className + ' ') > -1
    );
}

class AllBets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            players: [],
            winners: [],
            failed: [],
            isWinner: false,
        };
    }

    onChange(event, players, winner) {
        this.setState({ players: players, isWinner: winner });
    }

    onWinner(event, winners) {
        this.setState({ winners: winners });
    }

    render() {
        const { players, winners } = this.state;
        return (
            <Child
                engine={this.props.engine}
                onChange={this.onChange.bind(this, players)}
                onWinner={this.onWinner.bind(this, winners)}
            />
        );
    }
}

class Child extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            playersObject: [],
            winnersObject: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            let { engine } = this.props;

            engine.trigger.on('finish_crash', (data) => {
                this.syncFinishPlayer(data);
            });
            engine.trigger.on('play_crash', (data) => {
                this.syncPlayPlayer(data);
            });
            engine.trigger.on('busted_crash', (data) => {
                this.busted(data);
            });
            engine.trigger.on('waiting_crash', (data) => {
                this.isWaiting(data);
            });
            engine.trigger.on('game_status', (data) => {
                this.gameSync(data);
            });
            engine.trigger.on('game_players', (data) => {
                console.log(
                    '***GAME PLAYERS CRASH FROM BET*** IMPORTANT',
                    data
                );
                this.gameSync(data);
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    syncPlayPlayer(data) {
        if (this._isMounted) {
            let add = this.state.playersObject.concat(data);
            this.setState({ playersObject: add });
            this.props.onChange(this.state.playersObject);
        }
    }

    syncFinishPlayer(data) {
        if (this._isMounted) {
            let index = __.findIndex(this.state.playersObject, function (o) {
                return o.uid === data.uid;
            });
            this.state.playersObject.splice(index, 1);
            let add = this.state.winnersObject.concat(data);
            this.setState({ winnersObject: add });
            this.props.onWinner(this.state.winnersObject);
        }
    }

    gameSync(data) {
        if (this._isMounted) {
            sortByAmount(data.players).forEach((item, i) => {
                this.state.playersObject.push(item);
            });
            this.props.onChange(this.state.playersObject);

            sortByCashout(data.winners).forEach((item, i) => {
                this.state.winnersObject.push(item);
            });
            this.props.onWinner(this.state.winnersObject, true);
        }
    }

    busted(data) {
        if (this._isMounted) {
            this.setState({ playersObject: [], winnersObject: [] });
            this.state.playersObject.shift();
            this.state.winnersObject.shift();

            sortByAmount(data.players).forEach((item, i) => {
                this.state.playersObject.push(item);
            });

            this.props.onChange(this.state.playersObject);

            sortByCashout(data.winners).forEach((item, i) => {
                this.state.winnersObject.push(item);
            });

            this.props.onWinner(this.state.winnersObject, true);
        }
    }

    isWaiting(data) {
        if (this._isMounted) {
            this.setState({ playersObject: [], winnersObject: [] });

            sortByAmount(data.players).forEach((item, i) => {
                this.state.playersObject.push(item);
            });
            this.props.onChange(this.state.playersObject);
        }
    }

    render() {
        return (
            <ShowUserTable
                engine={this.props.engine}
                players={this.state.playersObject}
                winners={this.state.winnersObject}
            />
        );
    }
}

class UsersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (isMobile()) {
            this.setState({ font: 'font-10' });
        }
    }

    render() {
        let playerRows = [];
        let winnerRows = [];
        let checkExists = [];

        if (this.props.players.length > 0) {
            this.props.players.forEach((currentPlayer, i) => {
                if (!checkExists.includes(currentPlayer.name)) {
                    checkExists.push(currentPlayer.name);
                    if (currentPlayer.session === 'crash')
                        playerRows.push(
                            <UserRow
                                engine={this.props.engine}
                                currentPlayer={currentPlayer}
                                key={i}
                                isWinner={false}
                                isFailed={false}
                            />
                        );
                }
            });
        }

        if (this.props.winners.length > 0) {
            this.props.winners.forEach((currentPlayer, i) => {
                if (!checkExists.includes(currentPlayer.name)) {
                    checkExists.push(currentPlayer.name);
                    if (currentPlayer.session === 'crash')
                        winnerRows.push(
                            <UserRow
                                engine={this.props.engine}
                                currentPlayer={currentPlayer}
                                key={i}
                                isWinner={true}
                                isFailed={false}
                            />
                        );
                }
            });
        }

        return (
            <>
                <div className={'table-responsive last-bets cq num-style mb-0'}>
                    <Table className={'game-table mb-0 ' + this.state.font}>
                        <thead>
                            <tr>
                                <th>PLAYER</th>
                                <th>PAYOUT</th>
                                <th className={'text-left'}>BET</th>
                                <th className={'text-left'}>PROFIT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {playerRows}
                            {winnerRows}
                        </tbody>
                    </Table>
                </div>
            </>
        );
    }
}

class ShowUserTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var size = 50;
        var players = sortByAmount(this.props.players);
        var winners = sortByCashout(this.props.winners);
        players.length = Math.min(players.length, size);
        winners.length = Math.min(winners.length, size);
        return (
            <UsersTable
                engine={this.props.engine}
                players={players}
                winners={winners}
            />
        );
    }
}

class UserRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        if (
            this.props.currentPlayer.uid === undefined ||
            this.props.currentPlayer.name === undefined
        )
            return null;

        let profit = this.props.currentPlayer.won
            ? forceSatoshiFormat(this.props.currentPlayer.won)
            : '-';

        let self = '',
            type = 'white text-warning',
            cashout = '-';

        let bet = this.props.currentPlayer.amount;

        if (this.props.currentPlayer.token === storage.getKey('token'))
            self = 'bg-soft-warning';

        if (this.props.engine.gameStatus === 'busted') {
            if (profit === '-') type = 'text-danger';
        }

        if (profit !== '-') {
            type = 'text-success';
            cashout = (this.props.currentPlayer.current / 100).toFixed(2) + 'x';
        }

        return (
            <tr
                key={this.props.currentPlayer.uid}
                className={type + ' ' + self + ' q-crash text-center'}
            >
                <td className="text-left">
                    <img
                        src={`https://robohash.org/${this.props.currentPlayer.name}?size=20x20`}
                        alt="user"
                        className="rounded-circle thumb-sm"
                    />
                    <span>
                        {this.props.currentPlayer.name
                            ? this.props.currentPlayer.name.slice(0, 5)
                            : ''}
                    </span>
                    {/* <UserModal
                        username={this.props.currentPlayer.name}
                        queue={true}
                        avatar={this.props.currentPlayer.avatar}
                        isWinner={profit}
                    /> */}
                </td>
                <td className="text-left" size="20">
                    {' '}
                    {cashout}
                </td>
                <td className="text-left num-style">
                    <img
                        src={
                            '/assets/images/' +
                            this.props.currentPlayer.coin +
                            '.png'
                        }
                        alt="COIN"
                        className={'mini-coin-2'}
                    />
                    {forceSatoshiFormat(bet)}{' '}
                    <span className={'hidden-sm'}>
                        {__.upperCase(this.props.currentPlayer.coin)}
                    </span>{' '}
                </td>
                <td className="text-left num-style">
                    <img
                        src={
                            '/assets/images/' +
                            this.props.currentPlayer.coin +
                            '.png'
                        }
                        alt="COIN"
                        className={'mini-coin-2'}
                    />
                    {profit}{' '}
                    <span className={'hidden-sm'}>
                        {__.upperCase(this.props.currentPlayer.coin)}
                    </span>
                </td>
            </tr>
        );
    }
}

class MyBets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            players: [],
            game: 'crash',
            token: storage.getKey('token'),
            name: storage.getKey('name'),
            gameCode: md5('crash'),
        };
    }

    componentDidMount() {
        socket.emit(
            C.MY_BETS,
            encode({ token: this.state.token, game: this.state.game })
        );
        socket.on(C.MY_BETS, (data) => this.makeList(decode(data)));
        socket.on(C.ADD_BET, (data) => this.addList(decode(data)));
    }

    addList(player) {
        if (player.name === this.state.name) {
            let row = <Players forMy={true} player={player} />;
            this.setState((state) => ({ players: [row, ...state.players] }));
        }
    }

    makeList(arr) {
        if (typeof arr.history === 'undefined') return;
        this.setState({ loading: false });
        arr.history.forEach((player, i) => {
            let row = <Players key={i} forMy={true} player={player} />;
            this.setState((state) => ({ players: [row, ...state.players] }));
        });
    }

    render() {
        if (this.state.players.length !== 0) this.state.players.length = 10;

        return (
            <>
                <div className={'table-responsive last-bets num-style mb-0'}>
                    {this.state.loading ? (
                        <>
                            <div className="text-center">
                                <div
                                    className="spinner-grow text-white my-3"
                                    role="status"
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <Table className={'mb-0'}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Amount</th>
                                        <th>Profit</th>
                                        <th>Date/Time</th>
                                    </tr>
                                </thead>
                                <tbody>{this.state.players}</tbody>
                            </Table>
                            {this.state.players.length !== 0 && (
                                <>
                                    <div
                                        className={
                                            'alert text-uppercase bg-cs mt-2 font-13 text-white text-center'
                                        }
                                    >
                                        Not Played Yet
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }
}

class Players extends React.Component {
    gameDetail = () => {
        Event.emit('single_game_modal', { data: this.props.player });
    };

    render() {
        let { name, amount, profit, coin, created, gid, direct, forMy } =
            this.props.player;
        let isWinner = false;
        let date = fixDate(created);

        if (direct) {
            date = timeConvertor(created);
        }

        if (__.toNumber(profit) !== 0.0) isWinner = true;

        return (
            <tr className={'q-crash cpt'}>
                <td onClick={this.gameDetail}>{gid}</td>
                {forMy === false && (
                    <td>
                        <UserModal username={name} isWinner={isWinner} />
                    </td>
                )}
                <td
                    onClick={this.gameDetail}
                    className={
                        isWinner === true
                            ? 'text-success-2 num-style'
                            : 'num-style'
                    }
                >
                    <img
                        src={'/assets/images/' + __.upperCase(coin) + '.png'}
                        className={'mini-coin-2 hidden-sm'}
                        alt={coin}
                    />
                    {forceSatoshiFormat(amount)} {__.upperCase(coin)}
                </td>
                <td
                    onClick={this.gameDetail}
                    className={
                        isWinner === true
                            ? 'text-success-2 num-style'
                            : 'num-style'
                    }
                >
                    <img
                        src={'/assets/images/' + __.upperCase(coin) + '.png'}
                        className={'mini-coin-2 hidden-sm'}
                        alt={coin}
                    />
                    {forceSatoshiFormat(profit)} {__.upperCase(coin)}
                </td>
                <td onClick={this.gameDetail}>{date}</td>
            </tr>
        );
    }
}

function sortByAmount(input) {
    function r(c) {
        return c.amount ? -c.amount : null;
    }
    return __.sortBy(input, r);
}

function sortByCashout(input) {
    function r(c) {
        return c.current ? -c.current : null;
    }
    return __.sortBy(input, r);
}

export default Chat;
