import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import socket from '../../../Socket';
import { encode } from '../../../Helper';
import C from '../../../Constant';

function Deposit(props) {
    const [showModal, setShowModal] = useState(false);
    const [coin, setCoin] = useState();

    const handleClose = () => {
        setShowModal(false);
    };

    const deposit = () => {
        socket.emit(
            C.CREDIT,
            encode({ token: this.state.token, coin: this.state.currentCoin })
        );
    };

    return (
        <>
            <button
                onClick={() => {
                    setShowModal(true);
                }}
                className={'dropdown-item'}
            >
                <i className="dripicons-wallet text-muted ml-1 mr-2 text-drop" />
                Deposit
            </button>

            {showModal && (
                <Modal
                    size="md"
                    centered={true}
                    backdrop={'static'}
                    show={true}
                    onHide={handleClose}
                    aria-labelledby="setting-lg-modal"
                    // className={'animated ' + effect}
                >
                    <Modal.Header className="Header">
                        Deposit{' '}
                        <button
                            type="button"
                            className="close p-2"
                            onClick={handleClose}
                        >
                            <i className={'mdi mdi-close'} />
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group mb-1">
                            {/* <i className="mdi mdi-code-tags align-middle font-30" /> */}
                            <label className="text-white mt-1">Amount</label>
                            <input
                                onChange={(e) => setCoin(e.target.value)}
                                value={coin}
                                placeholder="Enter Coin Amount"
                                type="number"
                                className="form-control text-white brd15 mb-2"
                            />
                        </div>
                        <button
                            onClick={deposit}
                            className="btn btn-success-2 btn-block btn-md btn-block no-shadow"
                        >
                            <i className="mdi mdi-send nts" /> Deposit
                        </button>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

Deposit.propTypes = {};

export default Deposit;
