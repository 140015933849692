import React  from "react";
import {Card, Tab, Tabs} from "react-bootstrap";
import ManualBet from './includes/ManualBet';
// import AutoBet from './includes/AutoBet';
import {isMobile} from "../../../Helper";

class Bet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bet: 'manual'
        };
    }

    componentDidMount() {
        if(isMobile()){
            this.setState({ padding: 'p-1', margin: 'mt-1' })
        }
    }

    render() {
        let { padding, margin } = this.state;
        let { token, isLogged, mobile } = this.props;
        return (
            <ManualBet token={token} mobile={mobile} isLogged={isLogged} />
        );
    }
}

export default Bet;