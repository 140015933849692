import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import * as Cookies from 'js-cookie';
import UserHeader from './Parts/Header/PHeader-User';
import GuestHeader from './Parts/Header/PHeader';
import Sidebar from './Parts/PSideBar';
import Footer from './Parts/Footer';
import socket from '../../Socket';
import {
    Event,
    decode,
    encode,
    wait,
    randomString,
    sendNotfication,
} from '../../Helper';
import WalletAlert from '../Components/User/Wallet/Alert';
import C from '../../Constant';
import Login from './Auth/Login';
import storage from '../../Storage';
import useMetaMask from '../../hooks/metamask';
import { MetaMaskContext } from '../../hooks/metamask';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';

const Index = (props) => {
    const history = useHistory();
    const { connect, disconnect, isActive, account } = useMetaMask();
    const [status, setStatus] = useState(false);
    const metamaskValue = useContext(MetaMaskContext);
    const [showModal, setShowModal] = useState(false);
    const [effect, setEffect] = useState('');
    const [modalText, setModalText] = useState('');

    const [auth, setAuth] = useState(false);
    const [navHeight, setNavHeight] = useState(80);
    const get_wallet = useSelector((state) => state.items.get_wallet);
    const [address, setAddress] = useState(
        account || storage.getKey('address')
    );

    useEffect(() => {
        if (!isActive && !metamaskValue?.isLoading) {
            setModalText(`Problem Connecting To Crypto Wallet.
            Please Configure Metamask or Connect to a Smart Chain Network!`);
            setShowModal(true);
        }
        if (metamaskValue?.signature && account && isActive) {
            loginUser(account);
        }
    }, [metamaskValue, isActive, metamaskValue?.status]);

    const loginUser = (address) => {
        if (!address) return;
        setAddress(address);
        setShowModal(false);
        storage.setKey('address', address);
        console.log(address, '***SENDING LOGIN EVENT***');
        socket.emit(
            C.WALLET_CONNECT,
            encode({
                username: address,
                wallet_address: address,
            })
        );
    };

    useEffect(() => {
        Event.on('showAuthModal', (status) => activeAuth(status));
        socket.on(C.WALLET_CONNECT_ONLINE, (status) => login(decode(status)));
        socket.on(C.WALLET_CONNECT, (data) => setWalletConnect(decode(data)));

        const sessionCookie = Cookies.get('auth');

        if (storage.getKey('token') !== null && sessionCookie) {
            socket.emit(
                C.WALLET_CONNECT_ONLINE,
                encode({
                    jwt_token: storage.getKey('jwt_token'), //fake
                    user_token: storage.getKey('user_token'), //fake
                    security_key: storage.getKey('security_key'), //fake
                    secret_user: storage.getKey('secret_user'), //fake
                    secret_realtime: storage.getKey('secret_realtime'), //fake
                    client_system: storage.getKey('client_system'), //fake
                    token_key: storage.getKey('token_key'), //fake
                    secret_token: storage.getKey('secret_token'), //fake
                    token: storage.getKey('token'), // REAL
                    address: storage.getKey('address'),
                })
            );
        }

        window.addEventListener('resize', () => {
            let pnavbar = document.getElementById('pnavbar');
            setNavHeight(pnavbar.clientHeight);
        });
        let pnavbar = document.getElementById('pnavbar');
        setNavHeight(pnavbar.clientHeight);

        // connect();
    }, []);

    const setWalletConnect = (data) => {
        console.log(data, '***LOGIN STATUS***');
        if (data.status === true) {
            setStatus('Successfully Login, Please Wait...');
            Cookies.remove('session');
            Cookies.set('session', data.token, { expires: 14 });
            storage.setKey('token', data.token);

            //FAKES
            storage.setKey('user_token', randomString(50));
            storage.setKey('jwt_token', randomString(50));
            storage.setKey('secret_user', randomString(44));
            storage.setKey('secret_realtime', randomString(50));
            storage.setKey('security_key', randomString(10));
            storage.setKey('token_key', randomString(64));
            storage.setKey('secret_token', randomString(64));

            console.log('login data => ' + data.token);

            //REALS
            console.log(data);
            storage.setKey('name', data.name);
            storage.setKey('address', data.name);
            storage.setKey('avatar', data.avatar);
            storage.setKey('credit', JSON.stringify(data.credit));
            storage.setKey('room', data.room);
            storage.setKey('friends', data.friends);

            setShowModal(false);
            // setEffect('zoomOut');

            socket.emit(
                C.WALLET_CONNECT_ONLINE,
                encode({
                    jwt_token: storage.getKey('jwt_token'),
                    user_token: storage.getKey('user_token'),
                    security_key: storage.getKey('security_key'), //fake
                    secret_user: storage.getKey('secret_user'), //fake
                    secret_realtime: storage.getKey('secret_realtime'), //fake
                    client_system: storage.getKey('client_system'), //fake
                    token_key: storage.getKey('token_key'), //fake
                    secret_token: storage.getKey('secret_token'), //fake
                    token: data.token, // REAL
                })
            );

            history.push('/');
        } else {
            setStatus(data.status);
            // this.setState({
            //     status: data.status,
            //     submitted: false,
            //     disabled: false,
            // });
        }
    };

    const login = (data) => {
        setAuth(data.status);
        setAddress(data.address);
    };

    const activeAuth = (status) => {
        setAuth(status);
        setModalText(
            'You need to login to play. Please login using your crypto wallet first.'
        );
    };

    const handleClose = () => {
        setShowModal(false);
        setAuth(false);
    };

    let { content } = props;
    let wallet;

    try {
        wallet = props.get_wallet.show;
    } catch (e) {}

    const { t } = props;
    return (
        <>
            {storage.getKey('token') ? (
                <UserHeader
                    toggleChat={props.toggleChat}
                    show={props.show || true}
                    t={props.t}
                    location={props.location}
                    connect={connect}
                    disconnect={disconnect}
                    isActive={isActive}
                    address={storage.getKey('name')}
                    setStatus={setStatus}
                />
            ) : (
                <GuestHeader
                    toggleChat={props.toggleChat}
                    show={props.show || true}
                    t={props.t}
                    location={props.location}
                    connect={connect}
                    disconnect={disconnect}
                    isActive={isActive}
                    account={account}
                    setStatus={setStatus}
                />
            )}
            <div
                id="page-wrapper"
                className="page-wrapper page-wrapper-custom"
                style={{ paddingTop: `${navHeight - 40}px` }}
            >
                <Sidebar loggedIn={storage.getKey('token')} t={t} />
                <div className="page-content" id={'page-content'}>
                    {wallet && <WalletAlert t={t} uid={get_wallet.uid} />}
                    {content}
                </div>
            </div>

            {showModal && (
                <Modal
                    size="md"
                    centered={true}
                    backdrop={'static'}
                    show={true}
                    onHide={handleClose}
                    aria-labelledby="setting-lg-modal"
                    // className={'animated ' + effect}
                >
                    <Modal.Header className="Header">
                        Account{' '}
                        <button
                            type="button"
                            className="close p-2"
                            onClick={handleClose}
                        >
                            <i className={'mdi mdi-close'} />
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <h3
                            style={{
                                fontSize: '1rem',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                marginTop: '-2rem',
                            }}
                        >
                            {modalText}
                        </h3>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default Index;
